import { getCookie } from '../../utils/cookie'
import { ILocationCookieType } from '../StoreLocator/interfaces'
import { ILocationData } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/OmniPage/interfaces'
import { IStoreCodes } from '../Tenant/Services/GERS/Pricing/storelocator'

const getLocationData = () => {
  try {
    const existingLocationCookie: string | undefined = getCookie(ILocationCookieType.GEOLOCATION, null)
    const existingStoreCookie: string | undefined = getCookie(ILocationCookieType.STORE_CODES, null)
    if (existingLocationCookie) {
      const locationObject = JSON.parse(unescape(existingLocationCookie)) as any
      const locationData: ILocationData = {
        state: locationObject.state,
        zip: locationObject.zip,
        city: locationObject.city,
      }
      if (existingStoreCookie) {
        const codes: IStoreCodes = JSON.parse(unescape(existingStoreCookie)) as IStoreCodes
        locationData.code = codes.storeCode
      } else {
        locationData.code = 'XX'
      }
      return locationData
    }
    return null
  } catch (e) {
    return null
  }
}

export default getLocationData
